import React from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';
import CreateAccount from './CreateAccount';
import VerifyEmail from './VerifyEmail';

export default function ({match}) {
    return (
        <Switch>
            <Route exact path={`${match.path}/verify/:token`} component={VerifyEmail}/>
            <Route path={`${match.path}/`} component={CreateAccount}/>
        </Switch>
    );
}