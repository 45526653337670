import React, {useCallback, useContext, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import AccountContext from '../../AccountContext';
import {updateShul} from '../../api';
import CharacterCountingTextField from '../../components/CharacterCountingTextField';

function ChangeShulNameDialogContent({shul, onConfirm, onCancel, onError}) {
    const {authToken} = useContext(AccountContext);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(shul.name);
    const [secondName, setSecondName] = useState(shul.secondName || '');

    const handleNameChange = useCallback(event => setName(event.target.value), []);
    const handleSecondNameChange = useCallback(event => setSecondName(event.target.value), []);

    const changeShulName = useCallback(event => {
        event.preventDefault();
        if (name.length < 3 || (name === shul.name && secondName === shul.secondName)) return;

        setLoading(true);

        updateShul(authToken, shul.id, {name: name, secondName: secondName || null})
            .then(shul => {
                setLoading(false);
                onConfirm(shul);
            })
            .catch(err => {
                setLoading(false);
                onError(err);
            });
    }, [shul, authToken, name, secondName, onConfirm, onError]);

    return (
        <form onSubmit={changeShulName}>
            <DialogTitle>Change Name</DialogTitle>
            <DialogContent>
                <CharacterCountingTextField
                    label="Shul Name"
                    maxLength={30}
                    enforceMax
                    value={name}
                    error={name.length < 3}
                    helperText={name.length < 3 ? 'Name must be at least 3 characters long' : null}
                    margin="dense"
                    fullWidth
                    onChange={handleNameChange}
                    disabled={loading}
                />
                <CharacterCountingTextField
                    label="Second Shul Name (optional)"
                    maxLength={30}
                    enforceMax
                    value={secondName}
                    margin="dense"
                    fullWidth
                    onChange={handleSecondNameChange}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} disabled={loading}>
                    Cancel
                </Button>
                <ButtonWithProgress
                    type="submit"
                    color="secondary"
                    loading={loading}
                    disabled={name.length < 3 || (name === shul.name && secondName === shul.secondName)}
                >
                    Save
                </ButtonWithProgress>
            </DialogActions>
        </form>
    );
}

export function ChangeShulNameDialog({open, shul, onConfirm, onCancel, onError}) {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            {open &&
            <ChangeShulNameDialogContent
                shul={shul}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onError={onError}
            />}
        </Dialog>
    );
}

export function useChangeShulNameDialog(nameChangedHandler, errorHandler) {
    const [open, setOpen] = useState(false);
    const [shul, setShul] = useState(null);

    const openDialog = useCallback((shul, endOfService) => {
        setShul(shul);
        setOpen(true);
    }, []);

    const onConfirm = useCallback(shul => {
        setOpen(false);
        setShul(null);

        nameChangedHandler(shul);
    }, [nameChangedHandler]);

    const onCancel = useCallback(() => {
        setOpen(false);
        setShul(null);
    }, []);

    const onError = useCallback(err => {
        setOpen(false);
        setShul(null);

        errorHandler(err);
    }, [errorHandler]);

    return [
        <ChangeShulNameDialog
            open={open}
            shul={shul}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onError={onError}
        />,
        openDialog,
    ];
}