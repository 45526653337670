import React from 'react';
import {Button, CircularProgress, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    wrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default function ButtonWithProgress ({loading, disabled, children, className, styles, buttonClassName, buttonStyles, ...otherProps}) {
    const classes = useStyles();
    return (
        <div className={`${classes.wrapper} ${className || ''}`}>
            <Button
                className={buttonClassName}
                style={buttonStyles}
                disabled={disabled || loading}
                {...otherProps}
            >
                {children}
            </Button>
            {loading && <CircularProgress size={24} color="secondary" className={classes.progress}/>}
        </div>
    )
}

ButtonWithProgress.propTypes = {
    ...Button.propTypes,
    loading: PropTypes.bool,
};