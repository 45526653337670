import React, {useCallback, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import useBooleanState from '../hooks/useBooleanState';

function InfoDialogContent({title, content, onClose}) {
    return (
        <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </>
    )
}

export function InfoDialog({open, title, content, onClose}) {
    return (
        <Dialog open={open}>
            <InfoDialogContent title={title} content={content} onClose={onClose}/>
        </Dialog>
    );
}

export function useInfoDialog() {
    const [open, , setOpenTrue, setOpenFalse] = useBooleanState();
    const [[title, content], setTitleContent] = useState(['', '']);

    const openDialog = useCallback((title, content) => {
        setTitleContent([title, content]);
        setOpenTrue();
    }, [setTitleContent, setOpenTrue]);

    return [
        <InfoDialog open={open} title={title} content={content} onClose={setOpenFalse}/>,
        openDialog,
    ];
}