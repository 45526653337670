import React, {useContext} from 'react';
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import queryString from 'query-string';
import SignIn from './SignIn';
import AccountInfo from './AccountInfo';
import Lists from './Lists';
import CreateList from './CreateList';
import ResetPassword from './ResetPassword'
import ChangePassword from './ChangePassword';
import AccountContext from '../AccountContext';

function ProtectedRoute({path, location, ...otherProps}) {
    const {account} = useContext(AccountContext);
    if (account === null) {
        const emailAddr = queryString.parse(location.search).emailAddr;
        return (
            <Redirect
                from={path}
                to={{
                    pathname: '/account/sign-in',
                    state: {referrer: path, emailAddr: emailAddr},
                }}
            />
        );
    }
    return (
        <Route {...otherProps} path={path}/>
    );
}

export default function ({match, location}) {
    return (
        <Switch>
            <Route
                exact
                path={`${match.path}/sign-in`}
                render={(props) => <SignIn {...props}/>}
            />
            <ProtectedRoute
                exact
                path={match.path}
                location={location}
                component={AccountInfo}
            />
            <Route path={`${match.path}/reset-password`} component={ResetPassword}/>
            <ProtectedRoute
                exact
                path={`${match.path}/lists`}
                location={location}
                component={Lists}
            />
            <ProtectedRoute
                exact
                path={`${match.path}/new-list`}
                location={location}
                component={CreateList}
            />
            <ProtectedRoute
                exact
                path={`${match.path}/change-password`}
                location={location}
                component={ChangePassword}
            />
            <Redirect from="*" to={`${match.path}/lists`}/>
        </Switch>
    );
}