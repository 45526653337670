import React from 'react';
import {
    Typography,
    Link,
    ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Menu, Build, SupervisedUserCircle, GetApp} from '@material-ui/icons';
import Logo from './Logo';

const useMenuItemStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 1),
        display: 'inline-block',
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: theme.spacing(1),
    },
    title: {
        fontSize: '1.2rem',
        fontWeight: 400,
    },
}));

function MenuItem({icon, title}) {
    const classes = useMenuItemStyles();

    return (
        <span className={classes.root}>
            <span className={classes.icon}>{icon}</span>
            <span className={classes.title}>{title}</span>
        </span>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2),
    },
    question: {
        marginTop: theme.spacing(3),
        '&::before': {
            content: '\'Q: \'',
        },
    },
    answer: {
        textAlign: 'justify',
        '&::before': {
            content: '\'A: \'',
        },
        fontSize: '1.3rem',
        fontWeight: 300,
        '& a': {
            fontWeight: 400,
        },
        '& .MuiSvgIcon-root': {
            verticalAlign: 'sub',
        },
    },
}));

export default function () {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h1" align="center">FAQ</Typography>

            <Typography variant="h4" className={classes.question}>
                Can I use a custom domain for my list?
            </Typography>
            <Typography variant="body1" className={classes.answer}>
                Yes! For right now we'll need to set it up manually, so send us an email
                at <Link href="mailto:sales@misheberach.cloud">sales@misheberach.cloud</Link> and we'll guide you
                through the process.
            </Typography>

            <Typography variant="h4" className={classes.question}>
                How can I choose how long names stay on the list?
            </Typography>
            <Typography variant="body1" className={classes.answer}>
                Sign in to your <Logo/> list with your Admin account. Click <Menu/> to open the menu, then
                select <MenuItem icon={<Build/>} title="Configure"/>. Here you can set how long names should stay on
                the list, choose when to send emails, add a dedication (or change it), change the list order, and pick a
                color scheme.
            </Typography>

            <Typography variant="h4" className={classes.question}>
                How can I print the list for שבת?
            </Typography>
            <Typography variant="body1" className={classes.answer}>
                Go to your <Logo/> list, and click <GetApp/> in the top-right corner to download the list as a printable
                PDF file.
            </Typography>

            <Typography variant="h4" className={classes.question}>
                How do I create additional Admin accounts?
            </Typography>
            <Typography variant="body1" className={classes.answer}>
                Click <Menu/> to open the menu, then
                select <MenuItem icon={<SupervisedUserCircle/>} title="Manage Accounts"/>. Choose an account, then click
                "Role" to open the user roles menu. You can assign the Admin role to give the account full administrator
                privileges (they will not have access to your <Logo/> account, only to the list). You can also choose to
                assign the Gabbai role, which allows the account to manage names added to the list, but not accounts and
                the site configuration.
            </Typography>
        </div>
    );
}