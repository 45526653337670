import React, {useContext} from 'react';
import {
    makeStyles,
    Drawer,
    List, ListItem, ListItemIcon, ListItemText,
    Divider,
} from '@material-ui/core';
import {
    AccountBox,
    ExitToApp,
    ListAlt,
    PersonAdd,
    Person,
    Home,
    QuestionAnswer,
} from '@material-ui/icons';
import {Link, withRouter} from 'react-router-dom';
import AccountContext from './AccountContext';
import Logo from './Logo';

const useStyles = makeStyles(() => ({
    drawer: {
        width: 250,
    },
}));

const LinkListItem = withRouter(function ({location, path, icon, primaryText, secondaryText}) {
    return (
        <ListItem button component={Link} to={path} selected={location.pathname === path}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText
                primary={primaryText}
                secondary={secondaryText}
            />
        </ListItem>
    )
});

function GuestItems() {
    return (
        <>
            <LinkListItem
                path="/account/sign-in"
                icon={<AccountBox/>}
                primaryText="Sign In"
            />
            <LinkListItem
                path="/register"
                icon={<PersonAdd/>}
                primaryText="Register"
            />
        </>
    );
}

function AccountItems({account, signOut}) {
    return (
        <>
            <LinkListItem
                path="/account"
                icon={<Person/>}
                primaryText={account.name}
                secondaryText={account.emailAddr}
            />
            <LinkListItem
                path="/account/lists"
                icon={<ListAlt/>}
                primaryText="Your Lists"
            />
            <ListItem button onClick={signOut}>
                <ListItemIcon><ExitToApp/></ListItemIcon>
                <ListItemText>Sign Out</ListItemText>
            </ListItem>
        </>
    );
}

export default function ({open, close}) {
    const classes = useStyles();
    const {account, signOut} = useContext(AccountContext);

    return (
        <Drawer anchor="left" open={open} onClose={close}>
            <div
                tabIndex={0}
                role="button"
                onClick={() => setTimeout(close, 200)}
                onKeyDown={close}
            >
                <div className={classes.drawer}>
                    <List>
                        <LinkListItem
                            path="/"
                            icon={<Home/>}
                            primaryText={<Logo/>}
                        />
                    </List>
                    <Divider/>
                    <List>
                        {account === null ?
                            <GuestItems/> :
                            <AccountItems account={account} signOut={signOut}/>}
                    </List>
                    <Divider/>
                    <List>
                        <LinkListItem
                            path="/faq"
                            icon={<QuestionAnswer/>}
                            primaryText="FAQ"
                        />
                    </List>
                </div>
            </div>
        </Drawer>
    );
};