import React, {useState, useCallback, useEffect, useRef} from 'react';
import {
    createMuiTheme,
    AppBar,
    Paper,
    Toolbar,
    Typography,
    IconButton,
    Button,
    colors,
    useMediaQuery,
} from '@material-ui/core';
import {makeStyles, ThemeProvider} from '@material-ui/styles';
import {Menu} from '@material-ui/icons'
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import AccountContext from './AccountContext';
import Logo from './Logo';
import Home from './Home';
import Register from './Register';
import Account from './Account';
import FAQ from './FAQ';
import {getAccount, renewAuthToken, Account as AccountObj, parseToken} from './api';
import useBooleanState from './hooks/useBooleanState';
import NavDrawer from './NavDrawer';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colors.grey[900],
        },
        secondary: {
            main: colors.lightBlue[700],
        },
    },
    typography: {
        fontFamily: [
            'Rubik',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
        fontWeightRegular: 400,
        h2: {
            '@media(max-width: 500px)': {
                fontSize: '3rem',
            },
        },
    },
});

const useStyles = makeStyles({
    appBar: {
        width: 'calc(100% - 8px)',
        margin: '0 4px 4px 4px',
        top: 4,
        borderRadius: 8,
    },
    toolbar: {
        padding: '8px 12px',
    },
    title: {
        flexGrow: 1,
        fontWeight: 300,
        '@media(max-width: 450px)': {
            fontSize: '2.65rem',
        },
        '@media(max-width: 400px)': {
            fontSize: '2.35rem',
        },
        '@media(max-width: 370px)': {
            fontSize: '1.9rem',
        },
        marginLeft: 8,
    },
    body: {
        padding: theme.spacing(0.5),
    },
    paper: {
        width: '100%',
        borderRadius: 8,
        overflow: 'scroll',
        backgroundColor: 'white',
    },
});

function App() {
    const classes = useStyles();
    const {pathname} = useLocation();
    const paper = useRef(null);
    const smallScreen = useMediaQuery('(max-width: 550px)');
    const [drawerOpen, , openDrawer, closeDrawer] = useBooleanState(false);
    const [authToken, setAuthToken] = useState(null);
    const [account, setAccountState] = useState(null);

    const signOut = useCallback(() => {
        setAuthToken(null);
        setAccountState(null);
        window.sessionStorage.removeItem('authToken');
        window.sessionStorage.removeItem('account');
    }, []);

    const setAccount = useCallback((authToken, account) => {
        setAuthToken(authToken);
        setAccountState(account);

        window.sessionStorage.setItem('authToken', authToken);
        window.sessionStorage.setItem('account', JSON.stringify(account));

        const tokenPayload = parseToken(authToken);
        const exp = tokenPayload.exp * 1000;
        setTimeout(() => {
            if (authToken != null)
                renewAuthToken(authToken)
                    .then(({authToken, account}) => setAccount(authToken, account))
                    .catch(() => {
                        signOut();
                        // TODO: Account timeout message
                    });
        }, exp - (5 * 60 * 1000) - Date.now());
    }, [signOut]);

    useEffect(() => {
        const authToken = window.sessionStorage.getItem('authToken');
        if (authToken != null) {
            const tokenPayload = parseToken(authToken);
            const exp = tokenPayload.exp * 1000;
            if (exp > Date.now()) {
                const accountJson = window.sessionStorage.getItem('account');
                if (accountJson !== null) {
                    const account = JSON.parse(accountJson);
                    setAuthToken(authToken);
                    setAccountState(new AccountObj(account.id, account.emailAddr, account.name, account.role));
                }
                getAccount(authToken)
                    .then(account => setAccount(authToken, account));
            } else {
                signOut();
            }
        }
    }, [setAccount, signOut]);

    useEffect(() => {
        if (paper.current) paper.current.scrollTop = 0;
    }, [pathname]);

    return (
        <AccountContext.Provider value={{
            account: account,
            authToken: authToken,
            setAccount: setAccount,
            signOut: signOut,
        }}>
            <ThemeProvider theme={theme}>
                <AppBar className={classes.appBar} position="sticky">
                    <Toolbar className={classes.toolbar}>
                        <IconButton color="inherit" onClick={openDrawer}>
                            <Menu/>
                        </IconButton>
                        <Typography variant="h3" className={classes.title}>
                            <Logo/>
                        </Typography>
                        {!smallScreen &&
                        <Button
                            color="inherit"
                            component="a"
                            href="mailto:sales@misheberach.cloud"
                        >
                            Contact Us
                        </Button>}
                    </Toolbar>
                </AppBar>
                <main className={classes.body}>
                    <NavDrawer
                        open={drawerOpen}
                        close={closeDrawer}
                    />
                    <Paper className={classes.paper} elevation={4} ref={paper}>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route path="/register" component={Register}/>
                            <Route path="/account" component={Account}/>
                            <Route path="/faq" component={FAQ}/>
                            <Redirect from="*" to="/"/>
                        </Switch>
                    </Paper>
                </main>
            </ThemeProvider>
        </AccountContext.Provider>
    );
}

export default App;
