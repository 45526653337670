import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
    Typography,
    ListItem,
    Divider,
    Link,
    IconButton, Button,
    TextField,
    useMediaQuery,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ChevronLeft, OpenInNew, Check, Close} from '@material-ui/icons';
import {red} from '@material-ui/core/colors';
import AccountContext from '../../AccountContext';
import {changeAdminAccountPassword, getSubscriptionDetails} from '../../api';
import {useErrorDialog} from '../../dialogs/ErrorDialog';
import {useInfoDialog} from '../../dialogs/InfoDialog';
import IconButtonWithProgress from '../../components/IconButtonWithProgress';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import useFuse from '../../hooks/useFuse';
import {useChangeShulNameDialog} from './ChangeShulNameDialog';
import {useConfirmCancelSubscriptionDialog} from './ConfirmCancelSubscription';
import Logo from '../../Logo';


const useInfoListItemStyles = makeStyles({
    item: {
        display: 'flex',
        '@media(max-width: 550px)': {
            display: 'block',
        },
    },
    name: {
        flex: 1,
    },
});

function InfoListItem({name, value, children}) {
    const classes = useInfoListItemStyles();

    return (
        <ListItem className={classes.item} divider>
            <Typography variant="subtitle1" className={classes.name}
                        color="textSecondary">{name || children}</Typography>
            {typeof value === 'string' ? <Typography variant="body1">{value}</Typography> : value}
        </ListItem>
    );
}

const useStyles = makeStyles(theme => ({
    shulTitleContainer: {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    backButton: {
        alignSelf: 'start',
        marginRight: theme.spacing(1),
    },
    shulName: {
        flex: 1,
        alignSelf: 'center',
        '@media(max-width: 500px)': {
            '& h3': {
                fontSize: '1.7rem',
            },
            '& h4': {
                fontSize: '1.2rem',
            },
        },
    },
    editNameButtonContainer: {
        marginLeft: theme.spacing(1),
        '@media(max-width: 550px)': {
            display: 'flex',
            flexDirection: 'row-reverse',
            margin: theme.spacing(1),
        },
    },
    infoHeader: {
        margin: theme.spacing(2, 1, 1),
    },
    infoList: {
        paddingBottom: theme.spacing(1.5),
    },
}));

function ExternalLink({children, ...otherProps}) {
    return (
        <Link target="_blank" rel="noopener noreferrer" {...otherProps} >
            {children} <OpenInNew fontSize="inherit"/>
        </Link>
    );
}

export default function ({selectedShul, splitScreen, setSelectedShul, ...otherProps}) {
    const classes = useStyles();
    const {authToken, account} = useContext(AccountContext);

    const narrowLayout = useMediaQuery('@media(max-width: 550px)');

    const [newAdminPassword, setNewAdminPassword] = useState(null);
    const [newAdminPasswordLoading, setNewAdminPasswordLoading] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [subscriptionDetailsLoading, setSubscriptionDetailsLoading] = useState(false);

    const selectedShulFuse = useFuse([selectedShul])

    const handleShulChanged = useCallback(shul => {
        if (!selectedShulFuse.blown) {
            setSelectedShul({...shul});
        }
    }, [selectedShulFuse, setSelectedShul]);

    const [errorDialog, openErrorDialog] = useErrorDialog();
    const [infoDialog, openInfoDialog] = useInfoDialog();
    const [changeShulNameDialog, openChangeShulNameDialog] = useChangeShulNameDialog(handleShulChanged, openErrorDialog);
    const [confirmCancelSubscriptionDialog, openConfirmCancelSubscriptionDialog] =
        useConfirmCancelSubscriptionDialog(handleShulChanged, openErrorDialog);

    const handleNewAdminPasswordChanged = useCallback(event => setNewAdminPassword(event.target.value), []);
    const submitNewAdminPassword = useCallback(event => {
        event.preventDefault();
        if (newAdminPassword.length < 8) return;

        setNewAdminPasswordLoading(true);
        changeAdminAccountPassword(authToken, selectedShul.id, newAdminPassword)
            .then(() => {
                setNewAdminPasswordLoading(false);
                setNewAdminPassword(null);
                openInfoDialog('Password Changed', 'The admin account password has been changed successfully.');
            })
            .catch(error => {
                setNewAdminPasswordLoading(false);
                openErrorDialog(error);
            });
    }, [authToken, newAdminPassword, openErrorDialog, selectedShul, openInfoDialog]);

    const loadSubscriptionDetails = useCallback(() => {
        setSubscriptionDetailsLoading(true);
        getSubscriptionDetails(authToken, selectedShul.id)
            .then(details => {
                if (!selectedShulFuse.blown) {
                    setSubscriptionDetailsLoading(false);
                    setSubscriptionDetails(details);
                }
            })
            .catch(error => {
                if (!selectedShulFuse.blown) {
                    setSubscriptionDetailsLoading(false);
                    openErrorDialog(error);
                }
            });
    }, [authToken, openErrorDialog, selectedShul, selectedShulFuse]);

    useEffect(() => {
        setNewAdminPassword(null);
        setSubscriptionDetails(null);
        setSubscriptionDetailsLoading(false);
    }, [splitScreen, selectedShul]);

    return (
        <div {...otherProps}>
            {selectedShul != null && <>
                <div className={classes.shulTitleContainer}>
                    {!splitScreen &&
                    <IconButton className={classes.backButton} onClick={() => setSelectedShul(null)}>
                        <ChevronLeft/>
                    </IconButton>}
                    <div className={classes.shulName}>
                        <Typography variant="h3">
                            {selectedShul.name}
                        </Typography>
                        {selectedShul.secondName != null &&
                        <Typography variant="h4">{selectedShul.secondName}</Typography>}
                    </div>
                    {!narrowLayout && selectedShul.subscriptionStatus !== 'deactivated' &&
                    <div className={classes.editNameButtonContainer}>
                        <Button onClick={() => openChangeShulNameDialog(selectedShul)}>
                            Edit
                        </Button>
                    </div>}
                </div>
                {narrowLayout && selectedShul.subscriptionStatus !== 'deactivated' &&
                <div className={classes.editNameButtonContainer}>
                    <Button onClick={() => openChangeShulNameDialog(selectedShul)}>
                        Edit Name
                    </Button>
                </div>}
                <div className={classes.infoList}>
                    <Divider/>
                    {selectedShul.subscriptionStatus !== 'deactivated' ? <>
                            <InfoListItem name="Domain" value={
                                <ExternalLink href={`https://${selectedShul.domain}`}>
                                    {selectedShul.domain}
                                </ExternalLink>
                            }/>
                            <InfoListItem name="Email Address" value={selectedShul.emailAddr}/>
                            <InfoListItem name="Timezone" value={selectedShul.timezone.replace(/_/g, ' ')}/>
                            <ListItem className={classes.item} divider>
                                <Typography variant="subtitle2">
                                    Log in with your admin account
                                    at <ExternalLink href={`https://${selectedShul.domain}/account/sign-in`}
                                                     color="secondary">
                                    {selectedShul.domain}/
                                    <wbr/>
                                    account/
                                    <wbr/>
                                    sign-in
                                </ExternalLink> to configure your <Logo/> list and manage entries and user accounts.
                                </Typography>
                            </ListItem>
                            <InfoListItem name="Admin Account" value={account.emailAddr}/>
                            <InfoListItem name="Admin Password" value={
                                newAdminPassword == null ?
                                    <Button onClick={() => setNewAdminPassword('')}>
                                        Change Password
                                    </Button> :
                                    <form onSubmit={submitNewAdminPassword}>
                                        <TextField
                                            type="password"
                                            autoComplete="new-password"
                                            value={newAdminPassword}
                                            onChange={handleNewAdminPasswordChanged}
                                            disabled={newAdminPasswordLoading}
                                        />
                                        <IconButtonWithProgress
                                            type="submit"
                                            size="small"
                                            loading={newAdminPasswordLoading}
                                            disabled={newAdminPassword.length < 8}
                                        >
                                            <Check/>
                                        </IconButtonWithProgress>
                                        <IconButton
                                            size="small"
                                            onClick={() => setNewAdminPassword(null)}
                                        >
                                            <Close/>
                                        </IconButton>
                                    </form>
                            }/>
                            {subscriptionDetails == null ?
                                <InfoListItem name="Subscription Details" value={
                                    <ButtonWithProgress
                                        loading={subscriptionDetailsLoading}
                                        onClick={loadSubscriptionDetails}
                                    >
                                        Show
                                    </ButtonWithProgress>
                                }/> : <>
                                    <InfoListItem name="Subscription Status" value={subscriptionDetails.status}/>
                                    <InfoListItem
                                        name="Last Payment"
                                        value={subscriptionDetails.lastPayment != null ?
                                            subscriptionDetails.lastPayment.toLocaleDateString() : '—'}/>
                                    <InfoListItem
                                        name={subscriptionDetails.status === 'ACTIVE' ? 'Upcoming Payment' : 'Subscription Ends'}
                                        value={subscriptionDetails.nextPayment != null ?
                                            subscriptionDetails.nextPayment.toLocaleDateString() : '—'}
                                    />
                                    {subscriptionDetails.status === 'ACTIVE' &&
                                    <InfoListItem name="Cancel Subscription" value={
                                        <ButtonWithProgress
                                            style={{color: red[500]}}
                                            loading={subscriptionDetailsLoading}
                                            onClick={() =>
                                                openConfirmCancelSubscriptionDialog(selectedShul,
                                                    subscriptionDetails.lastPayment && subscriptionDetails.nextPayment)}
                                        >
                                            Cancel Subscription
                                        </ButtonWithProgress>
                                    }/>}
                                </>}
                        </> :
                        <InfoListItem name="Status" value="DEACTIVATED"/>}
                </div>
            </>}
            {errorDialog}
            {infoDialog}
            {changeShulNameDialog}
            {confirmCancelSubscriptionDialog}
        </div>
    );
}