import React, {useContext} from 'react';
import {Typography, Link, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {lightBlue} from '@material-ui/core/colors';
import {Link as RouterLink} from 'react-router-dom';
import AccountContext from './AccountContext';
import Logo from './Logo';


const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1.5),
        '& p, & a': {
            fontSize: '1.5rem',
            fontWeight: 300,
        },
    },
    signUpContainer: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        '@media(max-width: 525px)': {
            flexDirection: 'column',
        },
    },
    signUpButton: {
        marginRight: theme.spacing(1),
        display: 'block',
        lineHeight: 0.8,
        textAlign: 'center',
        color: lightBlue[800],
        backgroundColor: lightBlue[50],
        '&:hover': {
            backgroundColor: lightBlue[100],
        },
        '@media(max-width: 525px)': {
            marginBottom: theme.spacing(1),
            marginRight: 0,
        },
    },
    signInButton: {
        display: 'block',
        lineHeight: 0.6,
        textAlign: 'center',
    },
    infoParagraph: {
        textAlign: 'justify',
    },
    seeItList: {
        margin: 0,
    },
}));

export default function () {
    const classes = useStyles();
    const {account} = useContext(AccountContext);

    return (
        <div className={classes.container}>
            <Typography variant="h2" align="center">Welcome to</Typography>
            <Typography variant="h4" align="center"><Logo/>!</Typography>
            <div className={classes.signUpContainer}>
                {account == null && <>
                    <Button
                        className={classes.signUpButton}
                        size="large"
                        component={RouterLink}
                        to="/register"
                    >
                        <Typography variant="h4">Start Free Trial</Typography>
                        <Typography variant="caption">Try it free for 2 weeks, then $180/year</Typography>
                    </Button>
                    <Button
                        className={classes.signInButton}
                        variant="outlined"
                        size="large"
                        component={RouterLink}
                        to="/account/sign-in"
                    >
                        <Typography variant="caption">Already have an account?</Typography>
                        <br/>
                        <Typography variant="h6">Sign In</Typography>
                    </Button>
                </>}
            </div>
            <Typography variant="h2">
                Up-to-Date
            </Typography>
            <Typography variant="body2" className={classes.infoParagraph}>
                After a set number of days, <Logo/> will email congregants asking them to confirm that the names
                they added should remain on the list. Names that aren't renewed are automatically removed five days
                later.
            </Typography>
            <br/>
            <Typography variant="h2">
                Convenient
            </Typography>
            <Typography variant="body2" className={classes.infoParagraph}>
                Names can be added from anywhere, anytime. No more messy paper lists and
                hard to decipher hand writing. For שבת, the list can be printed with a single click.
            </Typography>
            <br/>
            <Typography variant="h2">
                Customizable
            </Typography>
            <Typography variant="body2" className={classes.infoParagraph}>
                Choose a color scheme, change the list order, and more. An optional dedication line in the
                header makes it simple to honor donors (or their chosen honorees).
            </Typography>
            <br/>
            <Typography variant="h2">
                See it in Action
            </Typography>
            <Typography className={classes.seeItList} variant="body2" component="ul">
                <li>
                    <Link href="https://sample.misheberach.cloud/" target="_blank" rel="noopener noreferrer"
                          underline="always">
                        Sample
                    </Link>
                </li>
                <li>
                    <Link href="https://9am.mi-sheberach.com/" target="_blank" rel="noopener noreferrer"
                          underline="always">
                        Beis Medrash of Cedarhurst ("Shtiebel") 9AM Minyan
                    </Link>
                </li>
                <li>
                    <Link href="https://misheberach.chofetzchaimtc.com/" target="_blank" rel="noopener noreferrer"
                          underline="always">
                        Chofetz Chaim Torah Center
                    </Link>
                </li>
            </Typography>
            <br/>
            <Typography variant="h2">
                Questions?
            </Typography>
            <Typography variant="body2" className={classes.infoParagraph}>
                Check the <Link component={RouterLink} to="/faq" underline="always">FAQ</Link> or feel free to email us
                at <Link href="mailto:sales@misheberach.cloud">sales@misheberach.cloud</Link>.
            </Typography>
            <br/>
        </div>
    )
}