import React, {useCallback, useState} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    Link,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import {requestPasswordReset, ApiErrorCode} from '../../api';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 auto',
        padding: theme.spacing(3),
        maxWidth: '25em',
    },
    textContainer: {
        maxWidth: '35em',
    },
    submitButton: {
        float: 'right',
        marginTop: theme.spacing(1),
    },
}));

const Status = Object.freeze({
    ENTER_EMAIL_ADDR: 1,
    SUBMITTING: 2,
    SUCCESS: 3,
    ERROR: 4,
    EMAIL_ADDRESS_NOT_FOUND: 5,
});
export default function () {
    const classes = useStyles();
    const [status, setStatus] = useState(Status.ENTER_EMAIL_ADDR);
    const [dirty, setDirty] = useState(false);
    const [emailAddr, setEmailAddr] = useState('');
    const [valid, setValid] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = useCallback(event => {
        const value = event.target.value;
        setEmailAddr(value);
        setValid(/\S+@\S+\.\S+/.test(value));
    }, []);

    const handleBlur = useCallback(() => {
        if (!dirty) setDirty(true);
    }, [dirty]);

    const submit = useCallback(event => {
        event.preventDefault();
        if (!valid) return;

        setStatus(Status.SUBMITTING);

        requestPasswordReset(emailAddr)
            .then(() => setStatus(Status.SUCCESS))
            .catch(err => {
                setError(err);
                setStatus(err.code === ApiErrorCode.EMAIL_ADDRESS_NOT_FOUND
                    ? Status.EMAIL_ADDRESS_NOT_FOUND
                    : Status.ERROR);
            });
    }, [emailAddr, valid]);

    switch (status) {
        case Status.ENTER_EMAIL_ADDR:
        case Status.SUBMITTING:
            return (
                <div className={classes.container}>
                    <form onSubmit={submit}>
                        <Typography variant="h3" align="center" gutterBottom>Reset Password</Typography>
                        <Typography variant="h5" gutterBottom>Enter your email address:</Typography>
                        <TextField
                            type="email"
                            label="Email Address"
                            error={dirty && !valid}
                            helperText={dirty && !valid && 'Please enter a valid email address'}
                            value={emailAddr}
                            variant="filled"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <ButtonWithProgress
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.submitButton}
                            loading={status === Status.SUBMITTING}
                            disabled={!valid}
                        >
                            Submit
                        </ButtonWithProgress>
                    </form>
                </div>
            );
        case Status.SUCCESS:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Email sent.</Typography>
                    <Typography variant="h6" gutterBottom>
                        Check your inbox for instructions for how to finish resetting your password.
                    </Typography>
                </div>
            );
        case Status.ERROR:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Something went wrong.</Typography>
                    <Typography variant="h6" gutterBottom>
                        An error occurred while trying to reset your password:
                        <br/>
                        <code>[{error.code}] {error.title}: {error.detail}</code>
                    </Typography>
                </div>
            );
        case Status.EMAIL_ADDRESS_NOT_FOUND:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Email address not recognized.</Typography>
                    <Typography variant="h6" gutterBottom>
                        No account was found for that email address.
                        Click <Link component={RouterLink} to="/register" color="secondary"
                                    variant="inherit">here</Link> to register.
                    </Typography>
                </div>
            );
        default:
    }
}