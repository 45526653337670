export function Account(id, emailAddr, name) {
    this.id = id;
    this.emailAddr = emailAddr;
    this.name = name;
}

export function Shul(id, name, secondName, domain, emailAddr, timezone, subscriptionStatus) {
    this.id = id;
    this.name = name;
    this.secondName = secondName;
    this.domain = domain;
    this.emailAddr = emailAddr;
    this.timezone = timezone;
    this.subscriptionStatus = subscriptionStatus
}

export const ApiErrorCode = Object.freeze({
    GENERIC: 0,

    INVALID_PARAMS: 1000,
    INVALID_ARGS: 1001,

    UNAUTHORIZED: 2000,
    INVALID_CREDENTIALS: 2001,
    INVALID_TOKEN: 2002,
    EXPIRED_TOKEN: 2003,

    ACCOUNT_ALREADY_EXISTS: 4000,
    EMAIL_ADDRESS_ALREADY_VERIFIED: 4001,
    ACCOUNT_NOT_FOUND: 4002,
    EMAIL_ADDRESS_NOT_FOUND: 4003,

    DOMAIN_UNAVAILABLE: 5000,
    INVALID_PROMO_CODE: 5001,
    SUBSCRIPTION_NOT_FOUND: 5002,
    LIST_NOT_FOUND: 5003,
});

const baseUrl = process.env.REACT_APP_API_URL;

export function createAccount(emailAddr) {
    return fetch(`${baseUrl}/account`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email_addr: emailAddr,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return true;
            }
            throw response['data']['error'];
        });
}

export function verifyEmail(token, name, password) {
    return fetch(`${baseUrl}/account`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            name: name,
            password: password,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return response['data'];
            }
            throw response['data']['error'];
        });
}

export function signIn(emailAddr, password) {
    return fetch(`${baseUrl}/auth`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email_addr: emailAddr,
            password: password,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const authToken = response['data']['access_token'];
                return getAccount(authToken)
                    .then(account => ({
                        authToken: authToken,
                        account: account,
                    }))
            }
            throw response['data']['error'];
        });
}

export function getAccount(authToken) {
    return fetch(`${baseUrl}/account`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const account = response['data']['account'];
                return new Account(account.id, account.email_addr, account.name);
            }
            throw response['data']['error'];
        });
}

export function changePassword(authToken, oldPassword, newPassword) {
    return fetch(`${baseUrl}/account/password`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            old_password: oldPassword,
            new_password: newPassword,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return true;
            }
            throw response['data']['error'];
        });
}

export function requestPasswordReset(emailAddr) {
    return fetch(`${baseUrl}/account/reset-password`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email_addr: emailAddr,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return true;
            }
            throw response['data']['error'];
        });
}

export function resetPassword(authToken, newPassword) {
    return fetch(`${baseUrl}/account/reset-password`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            new_password: newPassword,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return true;
            }
            throw response['data']['error'];
        });
}

export function renewAuthToken(authToken) {
    return fetch(`${baseUrl}/auth`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const authToken = response['data']['access_token'];
                return getAccount(authToken)
                    .then(account => ({
                        authToken: authToken,
                        account: account,
                    }))
            }
            throw response['data']['error'];
        });
}

export function getShuls(authToken) {
    return fetch(`${baseUrl}/lists`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return response['data']['lists'].map(shul => new Shul(
                    shul['id'],
                    shul['name'],
                    shul['second_name'],
                    shul['domain'],
                    shul['email_addr'],
                    shul['timezone'],
                    shul['subscription_status'],
                ));
            }
            throw response['data']['error'];
        });
}

export function changeAdminAccountPassword(authToken, id, newPassword) {
    return fetch(`${baseUrl}/lists/${id}/admin`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            password: newPassword,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return true;
            }
            throw response['data']['error'];
        });
}

export function requestSubscription(authToken, name, secondName, domain, emailAddr, timezone, promoCode) {
    return fetch(`${baseUrl}/lists`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            name: name,
            second_name: secondName,
            domain: domain,
            email_addr: emailAddr,
            timezone: timezone,
            promo_code: promoCode,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return response['data']['subscription_id'];
            }
            throw response['data']['error'];
        });
}

export function updateShul(authToken, shulId, {name, secondName, timezone}) {
    return fetch(`${baseUrl}/lists/${shulId}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
            name: name,
            second_name: secondName,
            timezone: timezone,
        }),
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const shul = response['data']['list'];
                return new Shul(
                    shul['id'],
                    shul['name'],
                    shul['second_name'],
                    shul['domain'],
                    shul['email_addr'],
                    shul['timezone'],
                    shul['subscription_status'],
                );
            }
            throw response['data']['error'];
        });
}

export function cancelShulSubscription(authToken, shulId) {
    return fetch(`${baseUrl}/lists/${shulId}/subscription`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return response['data']['list_status'];
            }
            throw response['data']['error'];
        });
}

export function getSubscriptionDetails(authToken, shulId) {
    return fetch(`${baseUrl}/lists/${shulId}/subscription`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const details = response['data'];
                return {
                    status: details['status'],
                    lastPayment: details['last_payment'] && new Date(details['last_payment']),
                    nextPayment: details['next_payment'] && new Date(details['next_payment']),
                };
            }
            throw response['data']['error'];
        });
}

export function pokePendingSubscription(authToken, subscription) {
    return fetch(`${baseUrl}/lists/pending/${subscription}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                return response['data']['activated'];
            }
            throw response['data']['error'];
        });
}

export function getPromoCodeDetails(authToken, promoCode) {
    return fetch(`${baseUrl}/promo-code/${promoCode}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
    })
        .then(response => response.json())
        .then(response => {
            if (response['status'] === 'ok') {
                const plan = response['data']['plan'];
                return {
                    freeTrial: plan['free_trial'],
                    firstYearPrice: plan['first_year_price'],
                    price: plan['price'],
                    cycle: plan['cycle'],
                };
            }
            throw response['data']['error'];
        });
}

export function parseToken(token) {
    const base64Payload = token.split('.')[1]
        .replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64Payload));
}