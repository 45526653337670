import React, {useCallback, useContext, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {red} from '@material-ui/core/colors';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import AccountContext from '../../AccountContext';
import {cancelShulSubscription} from '../../api';

function ConfirmCancelSubscriptionDialogContent({shul, endOfService, onConfirm, onCancel, onError}) {
    const {authToken} = useContext(AccountContext);
    const [loading, setLoading] = useState(false);

    const cancelSubscription = useCallback(() => {
        setLoading(true);

        cancelShulSubscription(authToken, shul.id)
            .then(newStatus => {
                setLoading(false);
                shul.subscriptionStatus = newStatus;
                onConfirm(shul);
            })
            .catch(err => {
                setLoading(false);
                onError(err);
            });
    }, [shul, authToken, onConfirm, onError]);

    return (
        <>
            <DialogTitle>Cancel subscription for <b>{shul.name}</b>?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {endOfService != null ?
                        `Your list will remain active until the end of the billing period on 
                     ${endOfService.toLocaleDateString()}.` :
                        'Your free trial will end and your list will be deactivated immediately.'
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonWithProgress
                    onClick={cancelSubscription}
                    style={loading ? undefined : {color: red[500]}}
                    loading={loading}
                >
                    Yes, cancel my subscription
                </ButtonWithProgress>
            </DialogActions>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onCancel}
                    disabled={loading}
                >
                    Don't cancel my subscription
                </Button>
            </DialogActions>
        </>
    );
}

export function ConfirmCancelSubscriptionDialog({open, shul, endOfService, onConfirm, onCancel, onError}) {
    return (
        <Dialog open={open}>
            {open &&
            <ConfirmCancelSubscriptionDialogContent
                shul={shul}
                endOfService={endOfService}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onError={onError}
            />}
        </Dialog>
    );
}

export function useConfirmCancelSubscriptionDialog(subscriptionCancelledHandler, errorHandler) {
    const [open, setOpen] = useState(false);
    const [shul, setShul] = useState(null);
    const [endOfService, setEndOfService] = useState(null);

    const openDialog = useCallback((shul, endOfService) => {
        setShul(shul);
        setEndOfService(endOfService);
        setOpen(true);
    }, []);

    const onConfirm = useCallback(shul => {
        setOpen(false);
        setShul(null);
        setEndOfService(null);

        subscriptionCancelledHandler(shul);
    }, [subscriptionCancelledHandler]);

    const onCancel = useCallback(() => {
        setOpen(false);
        setShul(null);
        setEndOfService(null);
    }, []);

    const onError = useCallback(err => {
        setOpen(false);
        setShul(null);
        setEndOfService(null);

        errorHandler(err);
    }, [errorHandler]);

    return [
        <ConfirmCancelSubscriptionDialog
            open={open}
            shul={shul}
            endOfService={endOfService}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onError={onError}
        />,
        openDialog,
    ];
}