import React, {useCallback, useContext, useState} from 'react';
import {
    makeStyles,
    TextField,
    Typography, Link,
} from '@material-ui/core';
import ButtonWithProgress from '../components/ButtonWithProgress';
import {signIn, ApiErrorCode} from '../api';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import AccountContext from '../AccountContext';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 auto',
        padding: theme.spacing(3),
        maxWidth: '20em',
    },
    textContainer: {
        maxWidth: '35em',
    },
    form: {
        overflow: 'hidden',
        marginBottom: theme.spacing(1),
    },
    submitButton: {
        float: 'right',
        marginTop: theme.spacing(1),
    },
}));

const Status = Object.freeze({
    SIGN_IN: 1,
    SUBMITTING: 2,
    INVALID_CREDENTIALS: 3,
    ERROR: 4,
});
export default function ({path, history, location}) {
    const classes = useStyles();
    const {account, setAccount} = useContext(AccountContext);
    const [status, setStatus] = useState(Status.SIGN_IN);
    const [emailAddr, setEmailAddr] = useState((location.state || {}).emailAddr || '');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleEmailAddrChange = useCallback(event => setEmailAddr(event.target.value), []);
    const handlePasswordChange = useCallback(event => setPassword(event.target.value), []);

    const submit = useCallback(event => {
        event.preventDefault();
        if (!emailAddr || !password) return;

        setStatus(Status.SUBMITTING);

        signIn(emailAddr, password)
            .then(({authToken, account}) => {
                setAccount(authToken, account);
                const referrer = (location.state || {}).referrer;
                history.push(referrer || '/account/lists');
            })
            .catch(err => {
                setStatus(err.code === ApiErrorCode.INVALID_CREDENTIALS ? Status.INVALID_CREDENTIALS : Status.ERROR);
                setError(err);
            });
    }, [emailAddr, history, location.state, password, setAccount]);

    if (account !== null) {
        const referrer = (location.state || {}).referrer;
        return (
            <Redirect
                from={path}
                to={referrer || '/account/lists'}
            />
        );
    }

    switch (status) {
        case Status.SIGN_IN:
        case Status.SUBMITTING:
        case Status.INVALID_CREDENTIALS:
            return (
                <div className={classes.container}>
                    <form className={classes.form} onSubmit={submit}>
                        <Typography variant="h3" align="center" gutterBottom>Sign In</Typography>
                        {status === Status.INVALID_CREDENTIALS && (
                            <Typography variant="subtitle1" color="error">Incorrect email address or
                                password.</Typography>
                        )}
                        <TextField
                            type="email"
                            label="Email Address"
                            autoComplete="email"
                            error={status === Status.INVALID_CREDENTIALS}
                            value={emailAddr}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handleEmailAddrChange}
                            disabled={status === Status.SUBMITTING}
                        />
                        <TextField
                            type="password"
                            label="Password"
                            autoComplete="current-password"
                            error={status === Status.INVALID_CREDENTIALS}
                            value={password}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handlePasswordChange}
                            disabled={status === Status.SUBMITTING}
                        />
                        <ButtonWithProgress
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.submitButton}
                            loading={status === Status.SUBMITTING}
                            disabled={!emailAddr || !password}
                        >
                            Sign In
                        </ButtonWithProgress>
                    </form>
                    <Typography variant="body1" align="center">
                        <Link component={RouterLink} to="/account/reset-password" color="secondary">
                            Forgot your password?
                        </Link>
                    </Typography>
                </div>
            );
        case Status.ERROR:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Something went wrong.</Typography>
                    <Typography variant="h6" gutterBottom>
                        An error occurred while trying to sign in:
                        <br/>
                        <code>[{error.code}] {error.title}: {error.detail}</code>
                    </Typography>
                </div>
            );
        default:
    }
}