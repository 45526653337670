import React from 'react';
import {TextField, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';

function CharacterCounter(props) {
    const {charCount, max, ...otherProps} = props;
    return (
        <Typography
            variant="caption"
            align="right"
            color={charCount <= max ? 'textSecondary' : 'error'}
            style={{marginTop: '-4px', float: 'right'}}
            {...otherProps}>
            {charCount}/{max}
        </Typography>
    );
}

CharacterCounter.propTypes = {
    charCount: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
};

function HelperTextWithCharacterCounter(props) {
    const {text, charCount, max} = props;
    return (
        <>
            <span>{text}</span>
            {max && <CharacterCounter charCount={charCount} max={max}/>}
        </>
    );
}

HelperTextWithCharacterCounter.propTypes = {
    text: PropTypes.string,
    charCount: PropTypes.number,
    max: PropTypes.number,
};

export default function CharacterCountingTextField(props) {
    const {value, maxLength, helperText, enforceMax, inputProps, ...otherProps} = props;

    return (
        <TextField
            value={value}
            helperText={<HelperTextWithCharacterCounter text={helperText} charCount={value.length} max={maxLength}/>}
            inputProps={{maxLength: enforceMax ? maxLength : undefined, ...inputProps}}
            {...otherProps}
        />
    );
}

CharacterCountingTextField.propTypes = {
    value: PropTypes.string,
    maxLength: PropTypes.number,
    enforceMax: PropTypes.bool,
    helperText: PropTypes.string,
};