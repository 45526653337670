import React, {useCallback, useContext, useState} from 'react';
import {
    Container,
    Typography,
    TextField, InputAdornment,
    RadioGroup, Radio,
    FormControl, FormControlLabel,
    Select, MenuItem,
    Divider,
    Button,
    Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Forward, Clear} from '@material-ui/icons';
import {PayPalButton} from 'react-paypal-button-v2';
import {Link} from 'react-router-dom';
import CharacterCountingTextField from '../../components/CharacterCountingTextField';
import {timezoneNames} from '../../timezones';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import IconButtonWithProgress from '../../components/IconButtonWithProgress';
import Logo from '../../Logo';
import AccountContext from '../../AccountContext';
import {requestSubscription, getPromoCodeDetails, pokePendingSubscription, ApiErrorCode} from '../../api';
import {useErrorDialog} from '../../dialogs/ErrorDialog';
import LoadingBackdrop from '../../components/LoadingBackdrop';

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1),
    },
    sectionHeader: {
        marginTop: theme.spacing(1.5),
    },
    domainLabel: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    domainLabelRoot: {
        display: 'flex',
    },
    subdomainTextField: {
        maxWidth: 200,
    },
    customDomainTextField: {
        maxWidth: 350,
        marginBottom: 16,
    },
    textFieldTrailer: {
        alignSelf: 'center',
        marginTop: 2,
    },
    emailAddrContainer: {
        display: 'flex',
    },
    emailAddrTextField: {
        maxWidth: 100,
    },
    priceBreakdownContainer: {
        textTransform: 'uppercase',
        '@media(min-width: 550px)': {
            width: 300,
        },
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
    },
    priceBreakdownLine: {
        display: 'flex',
        '& p': {
            fontSize: '1.5rem',
            fontWeight: 300,
        },
    },
    priceBreakdownValue: {
        textAlign: 'right',
        flex: 1,
    },
    continueButtonContainer: {
        float: 'right',
        margin: theme.spacing(2, 0, 3),
    },
}));

const customDomainRegex = /^(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$/i;

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';

const defaultPlan = {
    freeTrial: '2 weeks',
    firstYearPrice: null,
    price: 180,
    cycle: "ANNUAL",
};

// Helper function that capitalizes first letter and makes the rest lowercase
function titleWord(word) {
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
}

export default function () {
    const classes = useStyles();
    const {authToken} = useContext(AccountContext);
    const [name, setName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [domainType, setDomainType] = useState('subdomain');
    const [subdomain, setSubdomain] = useState('');
    const [customDomain, setCustomDomain] = useState('');
    const [emailAddr, setEmailAddr] = useState('noreply');
    const [timezone, setTimezone] = useState(defaultTimezone);
    const [plan, setPlan] = useState(defaultPlan);
    const [promoCode, setPromoCode] = useState('');
    const [promoActive, setPromoActive] = useState(false);
    const [errors, setErrors] = useState({});
    const [subscription, setSubscription] = useState();
    const [loading, setLoading] = useState(false);
    const [promoCodeLoading, setPromoCodeLoading] = useState(false);
    const [pokeLoading, setPokeLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [errorDialog, openErrorDialog] = useErrorDialog();

    const handleNameChange = useCallback(event => setName(event.target.value), []);
    const handleSecondNameChange = useCallback(event => setSecondName(event.target.value), []);
    const handleDomainTypeChange = useCallback(event => setDomainType(event.target.value), []);
    const handleSubdomainChange = useCallback(event =>
        setSubdomain(event.target.value.replace(/[^A-Za-z0-9-]|(^-)/g, '')), []);
    const handleSubdomainBlur = useCallback(event =>
        setSubdomain(event.target.value.replace(/[^A-Za-z0-9-]|(^-)|(-$)/g, '')), []);
    const handleCustomDomainChange = useCallback(event => setCustomDomain(event.target.value), []);
    const handleEmailAddrChange = useCallback(event =>
        setEmailAddr(event.target.value.replace(/[^A-Za-z0-9-]|(^-)/g, '')), []);
    const handleEmailAddrBlur = useCallback(event =>
        setEmailAddr(event.target.value.replace(/[^A-Za-z0-9-]|(^-)|(-$)/g, '')), []);
    const handleTimezoneChange = useCallback(event => setTimezone(event.target.value), []);
    const handlePromoCodeChange = useCallback(event => setPromoCode(event.target.value.toUpperCase()), []);

    const handlePromoCodeButtonClick = useCallback(event => {
        event.preventDefault();
        if (promoCode === '') return;

        if (promoActive) {
            setPromoCode('');
            setPlan(defaultPlan);
            setPromoActive(false);
        } else {
            setPromoCodeLoading(true);
            getPromoCodeDetails(authToken, promoCode)
                .then(plan => {
                    setPlan(plan);
                    setPromoActive(true);
                    setErrors({...errors, promoCode: undefined});
                    setPromoCodeLoading(false);
                })
                .catch(error => {
                    if (error.code === ApiErrorCode.INVALID_PROMO_CODE) {
                        setErrors({...errors, promoCode: 'Invalid promo code'})
                    } else {
                        openErrorDialog(error);
                    }
                    setPromoCodeLoading(false);
                });
        }
    }, [promoActive, promoCode, authToken, errors, openErrorDialog]);

    const effectiveDomain = domainType === 'subdomain' ? `${subdomain}.misheberach.cloud` : customDomain;

    const submit = useCallback(() => {
        let validationError = false;
        let errors = {};

        if (name.length < 3) {
            validationError = true;
            errors.name = 'Name must be at least 3 characters long';
        }

        if (domainType === 'subdomain') {
            if (subdomain.length < 3) {
                validationError = true;
                errors.subdomain = 'Must be at least 3 characters';
            }
        } else {
            if (!customDomainRegex.test(customDomain)) {
                validationError = true;
                errors.subdomain = 'Please enter a valid domain';
            }
        }

        if (validationError) {
            setErrors(errors);
            return
        }

        setLoading(true);
        requestSubscription(
            authToken,
            name,
            secondName || null,
            effectiveDomain,
            `${emailAddr}@${effectiveDomain}`,
            timezone,
            promoActive ? promoCode : null,
        )
            .then(subscriptionId => {
                setLoading(false);
                setSubscription(subscriptionId);
            })
            .catch(error => {
                setLoading(false);
                switch (error['code']) {
                    case ApiErrorCode.INVALID_PARAMS:
                        errors.name = error['invalid_params']['name'];
                        errors.secondName = error['invalid_params']['second_name'];
                        errors[domainType] = error['invalid_params']['domain'];
                        errors.emailAddr = error['invalid_params']['email_addr'];
                        errors.timezone = error['invalid_params']['timezone'];
                        break;
                    case ApiErrorCode.DOMAIN_UNAVAILABLE:
                        errors[domainType] = 'Sorry, this domain is not available';
                        break;
                    case ApiErrorCode.INVALID_PROMO_CODE:
                        errors.promoCode = 'Invalid promo code';
                        setPromoActive(false);
                        break;
                    default:
                        openErrorDialog(error);
                }
                setErrors(errors);
            });
    }, [authToken, name, secondName, domainType, subdomain, customDomain, effectiveDomain, emailAddr, timezone,
        promoActive, promoCode, openErrorDialog, setErrors]);

    const handlePayPalApproved = useCallback(() => {
        setPokeLoading(true);
        setSubscription(null);

        return pokePendingSubscription(authToken, subscription)
            .then(active => {
                setPokeLoading(false);
                if (active) setSuccess(true);
            }).catch(error => {
                setPokeLoading(false);
                openErrorDialog(error);
            });
    }, [authToken, subscription, openErrorDialog]);

    const handlePayPalCanceled = useCallback(() => setSubscription(null), []);

    return (
        <Container className={classes.container} maxWidth="md">
            <Typography variant="h2" align="center">New List</Typography>
            <Typography variant="h4" className={classes.sectionHeader}>Name</Typography>
            <CharacterCountingTextField
                label="Shul Name"
                maxLength={30}
                enforceMax
                value={name}
                error={errors.name != null}
                helperText={errors.name}
                margin="dense"
                fullWidth
                onChange={handleNameChange}
                disabled={loading || subscription != null}
            />
            <CharacterCountingTextField
                label="Second Shul Name (optional)"
                maxLength={30}
                enforceMax
                value={secondName}
                error={errors.secondName != null}
                helperText={errors.secondName}
                margin="dense"
                fullWidth
                onChange={handleSecondNameChange}
                disabled={loading || subscription != null}
            />
            <Typography variant="h4" className={classes.sectionHeader}>Choose a Domain</Typography>
            <FormControl fullWidth>
                <RadioGroup value={domainType} onChange={handleDomainTypeChange}>
                    <FormControlLabel
                        value="subdomain"
                        control={<Radio/>}
                        disabled={loading || subscription != null}
                        classes={{root: classes.domainLabelRoot, label: classes.domainLabel}}
                        label={
                            <>
                                <TextField
                                    className={classes.subdomainTextField}
                                    placeholder="MyShul"
                                    value={subdomain}
                                    error={errors.subdomain != null}
                                    helperText={errors.subdomain}
                                    disabled={loading || subscription != null}
                                    style={errors.subdomain && {marginTop: 32}}
                                    onChange={handleSubdomainChange}
                                    onBlur={handleSubdomainBlur}
                                    margin="dense"
                                    inputProps={{maxLength: 25, spellCheck: false}}
                                />
                                <Typography className={classes.textFieldTrailer}>.misheberach.cloud</Typography>
                            </>
                        }
                    />
                    <FormControlLabel
                        value="customDomain"
                        control={<Radio/>}
                        disabled={true || loading}
                        classes={{root: classes.domainLabelRoot, label: classes.domainLabel}}
                        label={
                            <>
                                <TextField
                                    className={classes.customDomainTextField}
                                    label="Custom Domain"
                                    placeholder="misheberach.myshul.org"
                                    value={customDomain}
                                    error={errors.customDomain != null}
                                    helperText={errors.customDomain}
                                    disabled={true || loading || subscription != null}
                                    onChange={handleCustomDomainChange}
                                    margin="dense"
                                    inputProps={{maxLength: 40, spellCheck: false}}
                                />
                            </>
                        }
                    />
                </RadioGroup>
                <Typography variant="subtitle2">
                    Custom domains aren't supported yet during setup. If you would like to use a custom domain, please
                    complete setup with a subdomain and then email sales@misheberach.cloud, and we'll manually set up
                    your custom domain for you.
                    <br/>
                    Note: You must already own the domain you plan to use. <Logo/> does not perform domain
                    registrations, and the price of registering a domain is not included in your order.
                </Typography>
            </FormControl>
            <Typography variant="h4" className={classes.sectionHeader}>Choose an Email Address</Typography>
            <div className={classes.emailAddrContainer}>
                <TextField
                    className={classes.emailAddrTextField}
                    placeholder="noreply"
                    value={emailAddr}
                    error={errors.emailAddr != null}
                    helperText={errors.emailAddr}
                    margin="dense"
                    onChange={handleEmailAddrChange}
                    onBlur={handleEmailAddrBlur}
                    inputProps={{maxLength: 63 - effectiveDomain.length, spellCheck: false}}
                    disabled={loading || subscription != null}
                />
                <Typography className={classes.textFieldTrailer}>@{effectiveDomain}</Typography>
            </div>
            <Typography variant="subtitle2">
                This is the email address that emails from the site will come from.
            </Typography>
            <Typography variant="h4" className={classes.sectionHeader}>Time Zone</Typography>
            <Select value={timezone} onChange={handleTimezoneChange} disabled={loading || subscription != null}>
                {timezoneNames.map(tz =>
                    <MenuItem value={tz} key={tz}>{tz.replace(/_/g, ' ')}</MenuItem>)}
            </Select>
            <br/>
            <div className={classes.priceBreakdownContainer}>
                <form onSubmit={handlePromoCodeButtonClick}>
                    <TextField
                        label="Promo Code"
                        value={promoCode}
                        variant="outlined"
                        error={errors.promoCode != null}
                        helperText={promoActive ? 'Promo code applied!' : errors.promoCode}
                        margin="dense"
                        fullWidth
                        onChange={handlePromoCodeChange}
                        disabled={promoActive || promoCodeLoading || loading || subscription != null}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButtonWithProgress
                                        type="submit"
                                        edge="end"
                                        size="small"
                                        loading={promoCodeLoading}
                                        disabled={promoCode === ''}
                                    >
                                        {promoActive ? <Clear/> : <Forward/>}
                                    </IconButtonWithProgress>
                                </InputAdornment>,
                        }}
                    />
                </form>
                <div className={classes.priceBreakdownLine}>
                    <Typography variant="body2" color="textSecondary">Free Trial</Typography>
                    <Typography variant="body2" className={classes.priceBreakdownValue}>
                        {plan.freeTrial || '—'}
                    </Typography>
                </div>
                {plan.firstYearPrice != null &&
                <div className={classes.priceBreakdownLine}>
                    <Typography variant="body2" color="textSecondary">First Year</Typography>
                    <Typography variant="body2" className={classes.priceBreakdownValue}>
                        ${plan.firstYearPrice}
                    </Typography>
                </div>}
                <div className={classes.priceBreakdownLine}>
                    <Typography variant="body2" color="textSecondary">{titleWord(plan.cycle)} Price</Typography>
                    <Typography variant="body2" className={classes.priceBreakdownValue}>
                        ${plan.price}
                    </Typography>
                </div>
                <Divider/>
                <div className={classes.priceBreakdownLine}>
                    <Typography variant="body2" color="textSecondary">Pay Now</Typography>
                    <Typography variant="body2" className={classes.priceBreakdownValue}>
                        ${plan.freeTrial != null ? 0 : plan.firstYearPrice || plan.price}
                    </Typography>
                </div>
                <Dialog
                    open={subscription != null}
                    fullWidth
                    maxWidth="sm"
                >
                    {subscription != null &&
                    <>
                        <DialogContent>
                            <PayPalButton
                                createSubscription={() => subscription}
                                onApprove={handlePayPalApproved}
                                onCancel={handlePayPalCanceled}
                                options={{
                                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    vault: true,
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSubscription(null)}>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </>}
                </Dialog>
            </div>
            <div className={classes.continueButtonContainer}>
                <ButtonWithProgress
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={submit}
                >
                    Continue
                </ButtonWithProgress>
            </div>
            {errorDialog}
            <Dialog open={success}>
                <DialogTitle>List Created!</DialogTitle>
                <DialogContent>
                    Your <Logo/> list has been created successfully. You can access it at https://{effectiveDomain}.
                    Please allow up to 30 minutes for everything to be set up. In the meantime you may get an error
                    while trying to access your list.
                </DialogContent>
                <DialogActions>
                    <Button component={Link} to="/account/lists" variant="contained" color="primary">
                        GO TO LISTS
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackdrop open={pokeLoading}/>
        </Container>
    )
}