import React from 'react';
import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.appBar - 1,
    },
}));

export default function ({open}) {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress className={classes.progress} color="secondary"/>
        </Backdrop>
    );
}