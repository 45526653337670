import {useState, useEffect} from 'react';

function Fuse() {
    this.blown = false;
}

export default function (deps) {
    let [fuse, setFuse] = useState(null);
    const [prevDeps, setDeps] = useState(deps);

    useEffect(() => () => {
        fuse.blown = true
    }, [fuse]);

    for (let i = 0; i < deps.length; i++) {
        if (!Object.is(deps[i], prevDeps[i])) {
            fuse.blown = true;
            setDeps(deps);
            break;
        }
    }

    if (fuse == null || fuse.blown) {
        fuse = new Fuse();
        setFuse(fuse);
    }

    return fuse;
}

// function FusedPromise(fuse, promise) {
//     this.fuse = fuse;
//     this.promise = promise;
//
//     this.then = function (onFulfilled, onRejected) {
//         const blown = this.fuse.blown
//         function fusedOnFulfilled(...args) {
//             if (!blown) onFulfilled(...args);
//         }
//         if (onRejected !== undefined) {
//             function fusedOnRejected(...args) {
//                 if (!blown) onFulfilled(...args);
//             }
//         }
//         return new FusedPromise(this.promise.then(fusedOnFulfilled, fusedOnRejected));
//     }
// }
//
// export function attachFuse(fuse, promise) {
//     return
// }