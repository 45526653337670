import React from 'react';
import {CloudOutlined} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
    logo: {
        whiteSpace: 'nowrap',
        textTransform: 'none',
        '& .MuiSvgIcon-root': {
            verticalAlign: 'baseline !important',
        },
    },
});

export default function () {
    const classes = useStyles();

    return (
        <span className={classes.logo}>MiSheberach.<CloudOutlined fontSize="inherit" viewBox="0 0 24 16"/></span>
    )
}