import React, {useCallback, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import useBooleanState from '../hooks/useBooleanState';

function ErrorDialogContent({error, onClose}) {
    return (
        <>
            <DialogTitle>Something Went Wrong</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    An error occurred:
                    <br/>
                    <code>[{error.code}] {error.title}: {error.detail}</code>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </>
    )
}

export function ErrorDialog({open, error, onClose}) {
    return (
        <Dialog open={open}>
            <ErrorDialogContent error={error} onClose={onClose}/>
        </Dialog>
    );
}

export function useErrorDialog() {
    const [open, , setOpenTrue, setOpenFalse] = useBooleanState();
    const [error, setError] = useState();

    const openDialog = useCallback(error => {
        setError(error);
        setOpenTrue();
    }, [setError, setOpenTrue]);

    return [
        <ErrorDialog open={open} error={error} onClose={setOpenFalse}/>,
        openDialog,
    ];
}