import React from 'react';
import {
    List, ListItem, ListItemText, ListSubheader,
    Divider,
    Button,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ChevronRight} from '@material-ui/icons';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    newListButton: {
        margin: theme.spacing(0, 1, 1),
        width: `calc(100% - ${theme.spacing(2)}px)`,
    },
}));

export default function ({shuls, selectedShul, onSelection, ...otherProps}) {
    const classes = useStyles();

    return (
        <div {...otherProps}>
            <List color="white">
                <ListSubheader disableSticky key={0}>
                    YOUR LISTS
                </ListSubheader>
                <Divider/>
                {shuls.map(shul =>
                    <ListItem
                        key={shul.id}
                        button
                        divider
                        selected={selectedShul === shul}
                        onClick={() => onSelection(shul)}
                    >
                        <ListItemText>{shul.name}</ListItemText>
                        <ChevronRight/>
                    </ListItem>)}
            </List>
            <Button
                className={classes.newListButton}
                variant="contained"
                color="primary"
                component={RouterLink}
                to="/account/new-list"
            >
                New List
            </Button>
        </div>
    );
}