import React, {useCallback, useState} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    Link,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ButtonWithProgress from '../components/ButtonWithProgress'
import {verifyEmail, parseToken} from '../api';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 auto',
        padding: theme.spacing(3),
        maxWidth: '30em',
    },
    textContainer: {
        maxWidth: '35em',
    },
    submitButton: {
        float: 'right',
        marginTop: theme.spacing.unit,
    },
}));

const Status = Object.freeze({
    CHOOSE_PASSWORD: 1,
    SUBMITTING: 2,
    SUCCESS: 3,
    ERROR: 4,
});
export default function ({match}) {
    const classes = useStyles();
    const [status, setStatus] = useState(Status.CHOOSE_PASSWORD);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nameDirty, setNameDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
    const [error, setError] = useState(null);

    const {token} = match.params;
    const emailAddr = parseToken(token)['email_addr'];

    const handleNameChange = useCallback(event => setName(event.target.value), []);
    const handlePasswordChange = useCallback(event => setPassword(event.target.value), []);
    const handleConfirmPasswordChange = useCallback(event => setConfirmPassword(event.target.value), []);

    const handleNameBlur = useCallback(() => {
        if (!nameDirty) setNameDirty(true);
    }, [nameDirty]);
    const handlePasswordBlur = useCallback(() => {
        if (!passwordDirty) setPasswordDirty(true);
    }, [passwordDirty]);
    const handleConfirmPasswordBlur = useCallback(() => {
        if (!confirmPasswordDirty) setConfirmPasswordDirty(true);
    }, [confirmPasswordDirty]);

    const submit = useCallback(event => {
        event.preventDefault();

        setStatus(Status.SUBMITTING);

        const {token} = match.params;

        verifyEmail(token, name, password)
            .then(() => {
                setStatus(Status.SUCCESS);
            })
            .catch(err => {
                setStatus(Status.ERROR);
                setError(err);
            });
    }, [match.params, name, password]);

    const passwordValid = password.length >= 8;
    const passwordsMatch = password === confirmPassword;

    switch (status) {
        case Status.CHOOSE_PASSWORD:
        case Status.SUBMITTING:
            return (
                <div className={classes.container}>
                    <form onSubmit={submit}>
                        <Typography variant="h4" gutterBottom>Finish creating your account</Typography>
                        <TextField
                            label="Full Name/Name of Institution"
                            autoComplete="name"
                            error={name === '' && nameDirty}
                            value={name}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handleNameChange}
                            onBlur={handleNameBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <input type="email" value={emailAddr} hidden/>
                        <TextField
                            type="password"
                            label="Password"
                            autoComplete="new-password"
                            error={passwordDirty && !passwordValid}
                            helperText="Must be at least 8 characters"
                            value={password}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handlePasswordChange}
                            onBlur={handlePasswordBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <TextField
                            type="password"
                            label="Confirm Password"
                            autoComplete="new-password"
                            error={confirmPasswordDirty && !passwordsMatch}
                            helperText={confirmPasswordDirty && !passwordsMatch && 'Passwords do not match'}
                            value={confirmPassword}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handleConfirmPasswordChange}
                            onBlur={handleConfirmPasswordBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <ButtonWithProgress
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.submitButton}
                            loading={status === Status.SUBMITTING}
                            disabled={name === '' || !passwordValid || !passwordsMatch}
                        >
                            Submit
                        </ButtonWithProgress>
                    </form>
                </div>
            );
        case Status.SUCCESS:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Your account has been created.</Typography>
                    <Typography variant="h6" gutterBottom>
                        Your email address has been verified.
                        Click <Link component={RouterLink} to="/account/sign-in" color="secondary">here</Link> to sign
                        in.
                    </Typography>
                </div>
            );
        case Status.ERROR:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Something went wrong.</Typography>
                    <Typography variant="h6" gutterBottom>
                        An error occurred while trying to finish creating your account:
                        <br/>
                        <code>[{error.code}] {error.title}: {error.detail}</code>
                    </Typography>
                </div>
            );
        default:
    }
}