import React, {useContext, useEffect, useState} from 'react';
import {
    Divider,
    useMediaQuery,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import AccountContext from '../../AccountContext';
import {getShuls} from '../../api';
import ShulsList from './ShulsList';
import ShulDetails from './ShulDetails';
import LoadingBackdrop from '../../components/LoadingBackdrop';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        height: '100%',
        '@media(max-width: 749px)': {
            position: 'relative',
        },
    },
    listContainer: {
        overflow: 'scroll',
        width: 250,
        '@media(max-width: 749px)': {
            position: 'absolute',
            width: '100%',
            top: 0,
            '&.page-enter': {
                animation: 'slideInLeft 0.2s forwards',
            },
            '&.page-exit': {
                animation: 'slideOutLeft 0.2s forwards',
            },
        },
    },
    detailContainer: {
        overflow: 'scroll',
        flex: 1,
        padding: theme.spacing(1.5, 1.5, 0),
        '@media(max-width: 749px)': {
            width: '100%',
            top: 0,
            '&.page-enter': {
                animation: 'slideInRight 0.2s forwards',
            },
            '&.page-exit': {
                animation: 'slideOutRight 0.2s forwards',
            },
        },
    },
}));

function ToggleableTransitionGroup({enabled, ...props}) {
    return enabled ? <TransitionGroup {...props}/> : <div {...props}/>;
}

export default function ({history}) {
    const classes = useStyles();
    const splitScreen = useMediaQuery('(min-width: 750px)');
    const {authToken} = useContext(AccountContext);
    const [shuls, setShuls] = useState([]);
    const [selectedShul, setSelectedShul] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getShuls(authToken)
            .then(shuls => {
                if (shuls.length === 0)
                    history.push('/account/new-list');
                setShuls(shuls);
                setLoading(false);
            })
    }, [authToken, history]);

    useEffect(() => {
        if (shuls.length > 0 && splitScreen && selectedShul == null) setSelectedShul(shuls[0]);
    }, [shuls, splitScreen, selectedShul]);

    return (
        <ToggleableTransitionGroup enabled={!splitScreen} className={classes.container}>
            <CSSTransition timeout={200} classNames="page" key={selectedShul}>
                <>
                    {(splitScreen || selectedShul == null) &&
                    <ShulsList
                        className={classes.listContainer}
                        shuls={shuls}
                        selectedShul={selectedShul}
                        onSelection={setSelectedShul}
                    />}
                    {splitScreen &&
                    <Divider orientation="vertical"/>}
                    {(splitScreen || selectedShul != null) &&
                    <ShulDetails
                        className={classes.detailContainer}
                        selectedShul={selectedShul}
                        splitScreen={splitScreen}
                        setSelectedShul={setSelectedShul}
                    />}
                    <LoadingBackdrop open={loading}/>
                </>
            </CSSTransition>
        </ToggleableTransitionGroup>
    );
}