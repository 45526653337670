import React from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';
import RequestPasswordReset from './RequestPasswordReset';
import NewPassword from './NewPassword';

export default function ({match}) {
    return (
        <Switch>
            <Route exact path={`${match.path}/:token`} component={NewPassword}/>
            <Route path={`${match.path}/`} component={RequestPasswordReset}/>
        </Switch>
    );
}