import React, {useContext} from 'react';
import {Divider, makeStyles, Typography, Button, ListItem} from '@material-ui/core';
import {Link} from 'react-router-dom';
import AccountContext from '../AccountContext';

const useInfoListItemStyles = makeStyles({
    item: {
        display: 'flex',
    },
    name: {
        flex: 1,
    },
});

function InfoListItem({name, value}) {
    const classes = useInfoListItemStyles();

    return (
        <ListItem className={classes.item}>
            <Typography variant="subtitle1" className={classes.name}>{name}</Typography>
            <Typography variant="body1">{value}</Typography>
        </ListItem>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(1.5),
    },
    container: {
        padding: theme.spacing(1),
    },
}));

export default function () {
    const classes = useStyles();
    const {account} = useContext(AccountContext);

    return (
        <>
            <Typography className={classes.title} variant="h4" align="center" gutterBottom>
                Account
            </Typography>
            <Divider/>
            <InfoListItem name="Name" value={account.name}/>
            <Divider/>
            <InfoListItem name="Email Address" value={account.emailAddr}/>
            <Divider/>
            <InfoListItem name="Password" value={
                <Button color="primary" component={Link} to="/account/change-password">
                    CHANGE PASSWORD
                </Button>
            }/>
            <Divider/>
        </>
    );
}