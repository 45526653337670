import React, {useCallback, useState} from 'react';
import {
    makeStyles,
    TextField,
    Typography,
    Link,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ButtonWithProgress from '../../components/ButtonWithProgress'
import {resetPassword, parseToken} from '../../api';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 auto',
        padding: theme.spacing(3),
        maxWidth: '25em',
    },
    textContainer: {
        maxWidth: '35em',
    },
    submitButton: {
        float: 'right',
        marginTop: theme.spacing(1),
    },
}));

const Status = Object.freeze({
    CHOOSE_PASSWORD: 1,
    SUBMITTING: 2,
    SUCCESS: 3,
    ERROR: 4,
});
export default function ({match}) {
    const classes = useStyles();
    const [status, setStatus] = useState(Status.CHOOSE_PASSWORD);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
    const [error, setError] = useState(null);

    const handlePasswordChange = useCallback(event => setPassword(event.target.value), []);

    const handleConfirmPasswordChange = useCallback(event => setConfirmPassword(event.target.value), []);

    const handlePasswordBlur = useCallback(() => {
        if (!passwordDirty) setPasswordDirty(true);
    }, [passwordDirty]);

    const handleConfirmPasswordBlur = useCallback(() => {
        if (!confirmPasswordDirty) setConfirmPasswordDirty(true);
    }, [confirmPasswordDirty]);

    const {token} = match.params;
    const emailAddr = parseToken(token)['email_addr'];

    const passwordValid = password.length >= 8;
    const passwordsMatch = password === confirmPassword;

    const submit = useCallback(event => {
        event.preventDefault();
        if (!passwordValid || !passwordsMatch) return;

        setStatus(Status.SUBMITTING);

        resetPassword(token, password)
            .then(() => setStatus(Status.SUCCESS))
            .catch(err => {
                setStatus(Status.ERROR);
                setError(err);
            });
    }, [token, password, passwordValid, passwordsMatch]);

    switch (status) {
        case Status.CHOOSE_PASSWORD:
        case Status.SUBMITTING:
            return (
                <div className={classes.container}>
                    <form onSubmit={submit}>
                        <Typography variant="h4" gutterBottom>Choose a new password</Typography>
                        <input type="email" value={emailAddr} hidden/>
                        <TextField
                            type="password"
                            label="New Password"
                            autoComplete="new-password"
                            error={passwordDirty && !passwordValid}
                            helperText="Must be at least 8 characters"
                            value={password}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handlePasswordChange}
                            onBlur={handlePasswordBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <TextField
                            type="password"
                            label="Confirm Password"
                            autoComplete="new-password"
                            error={confirmPasswordDirty && !passwordsMatch}
                            helperText={confirmPasswordDirty && !passwordsMatch && 'Passwords do not match'}
                            value={confirmPassword}
                            variant="filled"
                            margin="dense"
                            fullWidth
                            onChange={handleConfirmPasswordChange}
                            onBlur={handleConfirmPasswordBlur}
                            disabled={status === Status.SUBMITTING}
                        />
                        <ButtonWithProgress
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.submitButton}
                            loading={status === Status.SUBMITTING}
                            disabled={!passwordValid || !passwordsMatch}
                        >
                            Submit
                        </ButtonWithProgress>
                    </form>
                </div>
            );
        case Status.SUCCESS:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Your password has been reset.</Typography>
                    <Typography variant="h6" gutterBottom>
                        Click <Link component={RouterLink} to="/account/sign-in" color="secondary"
                                    variant="inherit">here</Link> to sign in with your new password.
                    </Typography>
                </div>
            );
        case Status.ERROR:
            return (
                <div className={`${classes.container} ${classes.textContainer}`}>
                    <Typography variant="h4" gutterBottom>Something went wrong.</Typography>
                    <Typography variant="h6" gutterBottom>
                        An error occurred while trying to reset your password:
                        <br/>
                        <code>[{error.code}] {error.title}: {error.detail}</code>
                    </Typography>
                </div>
            );
        default:
    }
}